.game {
  padding-left: 30%;
  padding-top: 5%;
}

.game-board {
  display: grid;
  grid-template-columns: repeat(10, 20px);
  grid-template-rows: repeat(10, 20px);
}

.cell {
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
}

.snake {
  background-color: green;
  border-radius: 8px;
}

.food {
  background-color: red;
}

.game-over {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 10px;
  border: 1px solid black;
}
